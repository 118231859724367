import { createMachine, assign, send } from "xstate";

export const keeFSM = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QFsDGsDCALAhgOzzABsBiDAeQDlKBRDAFQG0AGAXUVAAcB7WASwAufbng4gAHogAsAJgA0IAJ7SAjMwB0AVikB2AJxSpAZgAcJgGwmdmgL42FaTLgLESAEQCSAZQrU6TNjEefiERMUkEc3klRDMZdWY9TSsjPSTZIxk7B3RsfEIidVQRQlQBSBIACRoAQQAlegAhWvoAfXoPDABpFnYkEGDBYVF+iLU9Iy0TQ2ZNcyNtKXMdBWUEGR1zdR0ZTWYVAw3NIx1rbJBHPJdC4pcyiur6ppbWgFkaLy8agHEaXqDeEMwqNEON4tMTiYZCodEZEtZVqCoeplntmDJZCoVFJmJZzpdnAUiiUwPcICR-v1BqERqAIppoms0pMTHoYTJ0WyTpo9PjcoTiOoIHxYLdSuUIOoAO44IZ4KAkOo0eh1ACa7U6PUCVMBNPCiBkJhU2yMKl2hz01kNiIQE006ixuzmppkyzSfKc+UFwtFJLJ0tlQnliuVaspXF1w31CEyxu0lgs0wsyQWNumxtxu1ScKiUJMHquRJ9YtJEoDcoV4YGkeBdOkrIdzCbKjmuLM6JtanMGhzpqke0yDLs9hAeG4EDgYgJXqIAJCUZBCAAtOYbUkEk3N1vN+YCwKbn6JXOgbSJKCdCYtJntEZTTCW0YbdzttZmEZzMZs1J8yPp9chSKJZkseeqLjIt4mmaPKyJaDImDa1iTKYiEJpo2LQnuM4Ab6dxlgATmAAh4YofDyiBC51pE6IOi6H4cps5gqI+MQIGo0IOgm0JpJahpZL+-JYcWh6QOWQZQORtZnjGb7qKkqGMWa5g8sxaxqEYUgcbssKmnozCnMONhAA */
    id: "keeFSM",
    initial: "disconnected",
    context: {
      retries: 0,
      retryInterval: 1000,
      retryTick: 1,
      heartbeatTimer: 0,
      heartbeatInterval: 10000,
      timeUntilNextRetry: 1000
    },
    states: {
      connected: {
        entry: [
          "resetHeartbeatTimer",
          "resetRetryInterval",
          "setTimeUntilNextRetryToRetryInterval"
        ],
        invoke: {
          id: "heartbeatInterval",
          src: () => (cb) => {
            const interval = setInterval(() => {
              cb("HEARTBEAT_TICK");
            }, 1000);

            return () => {
              clearInterval(interval);
            };
          }
        },
        always: {
          target: "disconnected",
          cond: (context) =>
            context.heartbeatTimer >= context.heartbeatInterval,
          actions: "resetHeartbeatTimer"
        },
        on: {
          HEARTBEAT_TICK: {
            actions: "incrementHeartbeatTimer"
          },
          HEARTBEAT_MESSAGE: {
            actions: "resetHeartbeatTimer"
          }
        }
      },
      disconnected: {
        initial: "waiting",
        states: {
          retrying: {
            entry: [
              "doubleRetryInterval",
              "setTimeUntilNextRetryToRetryInterval"
            ]
          },
          waiting: {
            invoke: {
              id: "retryCountdown",
              src: (context) => (cb) => {
                const interval = setInterval(() => {
                  cb("RETRY_TICK");
                }, 1000 * context.retryTick);

                return () => {
                  clearInterval(interval);
                };
              }
            },
            on: {
              RETRY_TICK: {
                actions: "tickTimeUntilNextRetry"
              },
              RETRY: {
                actions: "retryNow"
              }
            },
            always: {
              target: "retrying",
              cond: (context) => context.timeUntilNextRetry <= 0
            }
          }
        }
      }
    },
    on: {
      CONNECT: "connected",
      DISCONNECT: "disconnected"
    }
  },
  {
    actions: {
      sendConnect: send("CONNECT"),
      doubleRetryInterval: assign({
        retryInterval: (context) =>
          Math.min(context.retryInterval * 2, 64000) +
          Math.floor(Math.random() * 1000)
      }),
      resetRetryInterval: assign({
        retryInterval: 1000
      }),
      resetHeartbeatTimer: assign({
        heartbeatTimer: 0
      }),
      incrementHeartbeatTimer: assign({
        heartbeatTimer: (context) => context.heartbeatTimer + 1000
      }),
      setTimeUntilNextRetryToRetryInterval: assign({
        timeUntilNextRetry: (context) => context.retryInterval
      }),
      tickTimeUntilNextRetry: assign({
        timeUntilNextRetry: (context) =>
          context.timeUntilNextRetry - context.retryTick * 1000
      }),
      retryNow: assign({
        timeUntilNextRetry: 0
      })
    }
  }
);
